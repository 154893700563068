import React from "react";
import { Colors } from "../../Helpers";
import Text from "../Atoms/Text";

const Component = ({ children, bottom }) => (
  <Text
    size={14}
    line={17}
    weight="600"
    color={Colors.russian}
    bottom={bottom}
  >
    {children}
  </Text>
);

export default Component;
