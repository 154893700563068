import React from "react";
import { Colors } from "../../Helpers";
import Text from "../Atoms/Text";

const Component = ({ children, bottom }) => (
  <Text size={18} line={32} weight="400" color={Colors.independence} block bottom={bottom}>
    {children}
  </Text>
);

export default Component;
