import React from "react";

const Component = ({
  children,
  align = "flex-start",
  justify = "flex-start",
  direction = "row",
}) => (
  <div
    style={{
      display: "flex",
      justifyContent: justify,
      alignItems: align,
      flexDirection: direction,
    }}
  >
    {children}
  </div>
);

export default Component;
