import React from "react";

import Text from "../Atoms/Text";

const Component = ({ children, bottom }) => (
  <Text size={24} line={34} weight="600" block bottom={bottom}>
    {children}
  </Text>
);

export default Component;
