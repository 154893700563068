import React from "react";

import { HeroContainer, NavbarContainer, FooterContainer } from "../Containers";

import { Consent } from "../Components";

class Component extends React.PureComponent {
  render() {
    return (
      <div>
        <NavbarContainer />
        <HeroContainer />
        <FooterContainer />
        <Consent />
      </div>
    );
  }
}

export default Component;
