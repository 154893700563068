import React from "react";

import { Text } from "../../Components";

const Element = ({ name, website, description, image, url }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    style={{
      backgroundColor: "#F9F9F9",
      borderRadius: 5,
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
      position: "relative",
      cursor: "pointer !important",
      color: "inherit",
    }}
  >
    <div
      style={{
        flex: 1,
        padding: "24px 32px",
      }}
    >
      <Text block size={26} bottom={4} line={32} weight="600">
        {name}
      </Text>
      <Text block size={12} bottom={18} line={18} weight="600" color="#0062FE">
        <a href={url} target="_blank" rel="noopener noreferrer">
          {website}
        </a>
      </Text>
      <p>{description}</p>
    </div>
    <div style={{ flex: 1 }}>
      <div
        style={{
          height: "100%",
          display: "block",
          pointerEvents: "none",
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          width: "50%",
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
        }}
      />
    </div>
  </a>
);

export default Element;
