import React from "react";

const Component = ({ current = 0, dots = [0] }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      marginTop: 32,
      marginBottom: 32
    }}
  >
    {dots.map((dot, ___) => (
      <div
        style={{
          width: 10,
          height: 10,
          borderRadius: 999,
          margin: 5,
          backgroundColor: ___ === current ? "#0A1045" : "#D0D0D0",
        }}
      />
    ))}
  </div>
);

export default Component;
